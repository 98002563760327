





















































import ScholarEnrollmentAnalyticsComponent from "@/components/analyticsComponent/scholarAnalyticsComponent/scholarEnrollmentAnalyticsComponent/ScholarEnrollmentAnalyticsComponent";
export default ScholarEnrollmentAnalyticsComponent;
