<script>
import APP_CONST from "@/constants/AppConst";
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  name: "BarChart",
  props: ["data", 'isMobileWidth', 'hideBarAnnotation', 'barEmtyStateMessage', 'xAxisLabel', 'yAxisLabel', 'isDisplayXLabel', 'barChartBackground', 'barChartHoverBackground', 'maxYCoordinate', 'removeQualityGraph'],
  watch: {
    data(newData) {
      this.renderChartData()
    }
  },
  mounted() {
    this.renderChartData()
  },
  computed: {
    chartData() {

      let chartData = {
        labels: [],
        tooltip: [],
        isMobileWidth: this.isMobileWidth,
        hideBarAnnotation: this.hideBarAnnotation,
        datasets: [
          {
            label: "attendance",
            data: [],
            backgroundColor: [],
            hoverBackgroundColor: this.barChartHoverBackground,
            barPercentage: 1,
            categoryPercentage: 1,
            barThickness: 50
          }
        ],

      };
      let gridLineColor = [];
      gridLineColor.push("black");
      if (this.data) {
        for (let i = 0; i < this.data.length; i++) {
          gridLineColor.push(APP_CONST.STYLE.COLOR.NEUTRAL_100);
          chartData.labels.push(this.data[i].site);
          chartData.tooltip.push(this.data[i].tooltip);
          chartData.datasets[0].data.push(this.data[i].data);
          chartData.datasets[0].backgroundColor[i] = this.barChartBackground
        }
      }
      return chartData
    },
    options() {
      var rectangleSet = false;
      let options = {
        layout: {
          padding: {
            top: 20,
            bottom: 10
          }
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          displayColors: false,
          callbacks: {
            label: function (tooltipItems, data) {
              let index = data.labels.findIndex((item) => item == tooltipItems.label)
              return `${data.tooltip[index]}`;
            },
            title: function (tooltipItem, data) {
              return;
            }
          },
        },
        hoverBackgroundColor: false,
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
          position: 'bottom',
          align: 'center'
        },
        animation: {
          onComplete: function () {
            var scale = window.devicePixelRatio;
            var sourceCanvas = this.chart.chart.canvas;
            var copyWidth = this.chart.scales['y-axis-0'].width - 8;
            var copyHeight = this.chart.scales['y-axis-0'].height + this.chart.scales['y-axis-0'].top + 5;
            var targetCtx = document.getElementById("BarYAxis").getContext("2d");
            targetCtx.scale(scale, scale);
            targetCtx.canvas.width = copyWidth * scale;
            targetCtx.canvas.height = copyHeight * scale;
            targetCtx.canvas.style.width = `${copyWidth + 2}px`;
            targetCtx.canvas.style.height = `${copyHeight}px`;
            targetCtx.drawImage(sourceCanvas, 0, 0, copyWidth * scale, copyHeight * scale, 0, 0, copyWidth * scale, copyHeight * scale);
            var sourceCtx = sourceCanvas.getContext('2d');
            sourceCtx.clearRect(0, 0, copyWidth, copyHeight);
          }
        },
        hover: {
          animationDuration: 0
        },
        annotation: {
          drawTime: "afterDatasetsDraw",
          events: ["click"],
          annotations: [
            {
              id: "hline",
              type: "line",
              mode: "horizontal",
              scaleID: "y-axis-0",
              value: this.removeQualityGraph ? 0 : 80,
              borderColor: this.yAxisLabel == '% Enrolled' ? 'transparent' : APP_CONST.STYLE.COLOR.NEUTRAL_500,
              borderWidth: 0,
              borderDash: [7, 7],
              borderDashOffset: 5,
              label: {
                fontFamily: APP_CONST.STYLE.FONT_FAMILY.ARLY_FONT,
                fontSize: 10,
                fontStyle: 500,
                fontColor: APP_CONST.STYLE.COLOR.NEUTRAL_500,
                xPadding: this.isMobileWidth ? 3 : 0,
                yPadding: 0,
                cornerRadius: 0,
                position: "right",
                xAdjust: 3.2,
                yAdjust: 14,
                enabled: true,
                backgroundColor: APP_CONST.STYLE.COLOR.NEUTRAL_100,
                content: this.yAxisLabel == '% Enrolled' ? '' : ""
              },
            },
            {
              id: "hline2",
              type: "line",
              mode: "horizontal",
              scaleID: "y-axis-0",
              value: 55,
              borderColor: "transparent",
              borderWidth: 0,
              label: {
                fontFamily: APP_CONST.STYLE.FONT_FAMILY.ARLY_FONT,
                fontSize: 16,
                fontStyle: 'bold',
                fontColor: APP_CONST.STYLE.COLOR.NEUTRAL_900,
                xPadding: this.isMobileWidth ? 3 : 0,
                xAdjust: this.isMobileWidth ? document.documentElement.clientWidth > 430 && document.documentElement.clientWidth < 500 ? 85 : 50 : 500,
                yAdjust: 3,
                position: "left",
                enabled: true,
                backgroundColor: "transparent",
                content: this.barEmtyStateMessage ? this.barEmtyStateMessage : ''
              },
            }
          ]
        },
        scales: {
          xAxes: [
            {
              maxBarLength: 2,
              gridLines: {
                zeroLineWidth: 1,
                zeroLineColor: "black",
                drawTicks: false
              },
              ticks: {
                fontFamily: APP_CONST.STYLE.FONT_FAMILY.ARLY_FONT,
                fontSize: 12,
                fontColor: APP_CONST.STYLE.COLOR.NEUTRAL_900,
                maxRotation: 45,
                minRotation: 45,
                padding: 12,
                scaleBeginAtZero: false,
                beginAtZero: true,
              },
              scaleLabel: {
                display: true,
                labelString: "",
                fontSize: 16,
                fontStyle: "bold",
                fontColor: "black",
                fontFamily: APP_CONST.STYLE.FONT_FAMILY.ARLY_FONT
              }
            }
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Percent (%)",
                fontSize: 16,
                fontStyle: "bold",
                fontColor: "black",
                fontFamily: APP_CONST.STYLE.FONT_FAMILY.ARLY_FONT
              },
              ticks: {
                stepSize: this.maxYCoordinate ? (this.maxYCoordinate / 10) : 10,
                min: 0,
                beginAtZero: true,
                steps: 10,
                max: this.maxYCoordinate ? this.maxYCoordinate : 100,
                fontFamily: APP_CONST.STYLE.FONT_FAMILY.ARLY_FONT,
                fontSize: 14,
                fontColor: "black"
              },
              gridLines: {
                display: true,
                zeroLineWidth: 1,
                zeroLineColor: "black"
              }
            }
          ]
        }
      };
      if (this.hideBarAnnotation) {
        options.annotation = null;
      }
      options.scales.yAxes[0].scaleLabel.labelString = this.yAxisLabel;
      options.scales.xAxes[0].scaleLabel.labelString = this.xAxisLabel;
      options.scales.xAxes[0].scaleLabel.display = this.isDisplayXLabel;
      return options
    }
  },
  methods: {
    renderChartData() {
      this.renderChart(this.chartData, this.options);
    }
  }
};
</script>