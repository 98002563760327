import { ScreenText } from '@/lang/ScreenText';
import { Vue, Component, Prop } from 'vue-property-decorator';
@Component({
   components: {
   }
})

export default class ExportDropdown extends Vue {
   @Prop({ default: false })

   @Prop() menuOptions!: any

   @Prop() disable!: any

   private objScreenText: ScreenText = new ScreenText();
   public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
   }

   public isOpenDropdown: boolean = false;
   public isMobile: boolean = false;

   downloadCsv(event: any) {
      this.isOpenDropdown = !this.isOpenDropdown
   }

   callApi(arg: string) {
      this.isOpenDropdown = false;
      this.$emit("callapi", arg);
   }

}
